<template>
	<main>
		<div id="top" nitrohosting-id="MsavPWiSmyIhWaRo" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-fit">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="LFpdZgWkdlfMK9LI" class="nitrohosting-col">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="8erU6lPtKYgUjB2S" class="nitrohosting-anim_heading nitrohosting-heading-rotating nitrohosting-hide-tablet nitrohosting-hide-mobile">
							<vue-typed-js :strings="['Fast', 'Scalable', 'Secure', 'Reliable']" :loop=true :typeSpeed="50" :backSpeed="30" :backDelay="1500" :smartBackspace="true" :showCursor="true" :cursorChar="'_'" :autoInsertCss="false" class="nitrohosting-aheading-holder" style="">
								<p class="nitrohosting-animated-heading nitrohosting-rotating-text nitrohosting-words-wrapper">
									<span class="typing"></span>
								</p>
							</vue-typed-js>
						</div>
						<div nitrohosting-id="NpbP8NVgg8imvhBb" class="nitrohosting-anim_heading nitrohosting-heading-rotating nitrohosting-hide-desktop">
							<vue-typed-js :strings="['Fast', 'Scalable', 'Secure', 'Reliable']" :loop=true :typeSpeed="50" :backSpeed="30" :backDelay="1500" :smartBackspace="true" :showCursor="true" :cursorChar="'_'" :autoInsertCss="false" class="nitrohosting-aheading-holder" style="">
								<p class="nitrohosting-animated-heading nitrohosting-rotating-text nitrohosting-words-wrapper">
									<span class="typing"></span>
								</p>
							</vue-typed-js>
						</div>
						<div nitrohosting-id="rRdnrS18DJlbALgk" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder"> Hosting Solution</div>
						</div>
						<div nitrohosting-id="KMybJoUqJ2k80APc" class="nitrohosting-heading" style="text-align: left;">
							<div class="nitrohosting-heading-holder"> We are a full-service hosting company with quality service and reasonable price.
								<br /> We have various lines of product, including physical and virtual servers, games and
								<br /> applications containers, web hosting blocks.
							</div>
						</div>
						<div nitrohosting-id="rNFKIJAN43tGvq1p" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="BqCHaPbvGwXimZw9" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="Jh7PZlTNpedj2ADQ" class="nitrohosting-btn nitrohosting-animation-grow">
											<a v-scroll-to="'#choice'" class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-large nitrohosting-btn-icon-right">
												<i class="fas fa-caret-down nitrohosting-btn-icon"></i>
												<span class="nitrohosting-btn-text">Learn More</span>
												<i class="fas fa-caret-down nitrohosting-btn-icon"></i> </a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="choice" nitrohosting-id="KmdJTjtii2y3l5j1" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="e53Z81tt0VWQtrfS" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="7TjPQ1lKmyhNcSeq" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Popular Choices</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="leAXghalrJSy9iS2" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal nitrohosting-id="qum8Zrpu3ctdL6fT" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="QUbxO9x2TEFFQlak" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="3fc7U3iUc0cci4de" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>Game Hosting</h2></div>
										</div>
										<div nitrohosting-id="Lc785nD8Pvr9Pqhy" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> Need a game server to play with your friends? We host them for you!</div>
										</div>
										<div nitrohosting-id="VQosoEBXkLzK3iDu" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Game'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
								<div nitrohosting-id="tnSFgEAVbYxD46QH" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="0CNd2Win49ha3Jac" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>Web Hosting</h2></div>
										</div>
										<div nitrohosting-id="g8BZyIqfDuJjXTXS" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> Need a high-performance web server? We are here!</div>
										</div>
										<div nitrohosting-id="O2nvLsBWLwH8b4NR" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Web'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
								<div nitrohosting-id="lyg3288dZxKl7dZy" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="tJfkeMxmBNDvkJvR" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>VPS Hosting</h2></div>
										</div>
										<div nitrohosting-id="FYlKpnEm9IoQUz21" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> Powerful virtual server to fit your needs. Managed support included!</div>
										</div>
										<div nitrohosting-id="p8D7RnBIIgpu2xyc" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Vps'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
								<div nitrohosting-id="VW5lZhILgwhZX0LD" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="TyyooqEpZX0yBa4W" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>Dedicated</h2></div>
										</div>
										<div nitrohosting-id="epcZEKX3qbFriLRE" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> Ultimate managed dedicated server for your next level project!</div>
										</div>
										<div nitrohosting-id="eWkJ3Ma600z8vEQQ" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Dedicated'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="feature" nitrohosting-id="z03i7uQhci1DGITd" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-background-overlay"></div>
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="p9F0MLOZ6JELccSb" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="sG4XStqGanMvmisS" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Amazing Features</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="oCP9qgwhz233ftuF" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="xui49if7TemrTFGv" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="zrwcWtUFuS3cI5uu" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="wUVYl06QLIxlkWVl" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-left nitrohosting-service-vertical-middle">
												<div class="nitrohosting-service-icon nitrohosting-service-stacked">
													<i class="fas fa-dollar-sign nitrohosting-icon-circle" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading"> Affordable Pricing</div>
													<div class="nitrohosting-service-text">
														<p> Utilizing high frequency CPUs, allowing us to provide much better price to performance ratio.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="3yKKkrvqNwMTvq9t" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-left nitrohosting-service-vertical-middle">
												<div class="nitrohosting-service-icon nitrohosting-service-stacked">
													<i class="fas fa-bolt nitrohosting-icon-circle" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Insane Performance</div>
													<div class="nitrohosting-service-text">
														<p>We use top of the line hardware. With NVMe-SSD storage ensuring top performance across servers.</p>
													</div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="ROcvwJHMqkhLNklr" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-left nitrohosting-service-vertical-middle">
												<div class="nitrohosting-service-icon nitrohosting-service-stacked">
													<i class="fas fa-box nitrohosting-icon-circle" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">No Overselling</div>
													<div class="nitrohosting-service-text">
														<p>We never oversell our servers. Ensuring each server has dedicated resource, without noisy neighbour.</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="cnxszkprxZtzPn5i" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="CHCUO12X2P4pkCUB" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-left nitrohosting-service-vertical-middle">
												<div class="nitrohosting-service-icon nitrohosting-service-stacked">
													<i class="fas fa-cog nitrohosting-icon-circle" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Instant Setup</div>
													<div class="nitrohosting-service-text">
														<p>Once you’ve ordered from us, your servers are deployed instantly, waste no time waiting to access them!</p>
													</div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="PU5RT4nIWfXmWDaM" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-left nitrohosting-service-vertical-middle">
												<div class="nitrohosting-service-icon nitrohosting-service-stacked">
													<i class="fas fa-shield-alt nitrohosting-icon-circle" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Secured Environment</div>
													<div class="nitrohosting-service-text">
														<p>We scan all traffic going through our servers. With free DDoS protection, safe surfing!</p>
													</div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="wgQCe34JeHpB3hlZ" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-left nitrohosting-service-vertical-middle">
												<div class="nitrohosting-service-icon nitrohosting-service-stacked">
													<i class="fas fa-user-friends nitrohosting-icon-circle" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Friendly Support</div>
													<div class="nitrohosting-service-text">
														<p>Our staff members are trained for providing top-notch support. Additional services included for free too!</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="location" nitrohosting-id="VEJLyLpsWeEz6y6j" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="8IsfpesuMnqg1pjI" class="nitrohosting-col">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="qOPN7hqq76aa4Bij" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Locations</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="xnMvdURhoSCV8FJz" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="l4odiFF56Q2hDOKd" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div v-scroll-reveal nitrohosting-id="OSchJUILS56A0rL0" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="vbRum5AiBmQFaTlW" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> America</div>
										</div>
										<div nitrohosting-id="vv7uvlMOyD2bOz6o" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/newyork.jpg" alt="New York" />
												<div class="nitrohosting-image-overlay center">
													<div class="nitrohosting-image-overlay-content">
														<div class="nitrohosting-image-overlay-text">
															<p style="text-align: center;">
																<span style="font-size: 20px"><strong style="line-height: 1">New York City, NY Datacenter<br /></strong></span>
															</p></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-scroll-reveal nitrohosting-id="1dAvUrlmOlSkJeQ2" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="sq6J74n1aRNZmSEb" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> Europe</div>
										</div>
										<div nitrohosting-id="5qFVgqiLrmSHC5cm" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/germany.jpg" alt="Germany" />
												<div class="nitrohosting-image-overlay center">
													<div class="nitrohosting-image-overlay-content">
														<div class="nitrohosting-image-overlay-text">
															<p style="text-align: center;">
																<span style="font-size: 20px"><strong style="line-height: 1">Falkenstein, DE Datacenter<br /></strong></span>
															</p></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="58jSg7wI7hduc6Mt" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div v-scroll-reveal nitrohosting-id="xmeyu5YniC5D9dqx" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="9wHRgJpanHhGCkCM" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"> Asia</div>
										</div>
										<div nitrohosting-id="2PpFZQfiRQLHcsLE" class="nitrohosting-image" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/hongkong.jpg" alt="Hong Kong" />
												<div class="nitrohosting-image-overlay center">
													<div class="nitrohosting-image-overlay-content">
														<div class="nitrohosting-image-overlay-text">
															<p style="text-align: center;">
																<span style="font-size: 20px"><strong style="line-height: 1">Hong Kong, HK Datacenter<br /></strong></span>
															</p>
															<p style="text-align: center;">
																<span>Coming Soon!<br /></span></p></div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div v-scroll-reveal nitrohosting-id="bFqMRicSeVolos9f" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="xYAms7WoHtaWXhN1" class="nitrohosting-heading" style="text-align: center;">
											<a href="mailto:support@nitrohosting.pro?subject=Additional Service Location Suggestion&body=Hello, I would like to suggest a location of your services at:">
												<div class="nitrohosting-heading-holder"> Want more? Send us an email.</div>
											</a></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="why" nitrohosting-id="KAy4ZSmqH9LVMqe9" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="8Bcv3JL19KQs0tEy" class="nitrohosting-col nitrohosting-col-12">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="UYVEtUMjMXs9RoRx" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Why Choose Us?</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="Qpg6y3NHc3mLdeoy" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="f7XLh9CYZvEDsI6U" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="0O9fKw9wHCxyqv0z" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="oQ2RWwFrYgD2sTaz" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-microchip" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">High Clock Speed CPU</div>
													<div class="nitrohosting-service-text">
														<p>Our servers are equipped with high speed CPU on every plans.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="LBrA37fjRNGNEu4v" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-hdd" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">RAID M.2 NVMe Storage</div>
													<div class="nitrohosting-service-text">
														<p>Delivering extreme performance and protecting your data.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="HA8JtUHyQfFyjXNp" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-network-wired" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Balanced Server Loads</div>
													<div class="nitrohosting-service-text">
														<p>We have balanced system load, avoiding unexpected downtime.<br />
														</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="gddlvTvCcxCBO43X" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="0O5om6PGzEyJ2zP2" class="nitrohosting-image nitrohosting-hide-tablet nitrohosting-hide-mobile" style="text-align: center;">
											<div class="nitrohosting-image-holder">
												<img class="nitrohosting-img" src="https://img.nitrohosting.pro/cropped-icon-resized.png" alt="Icon" />
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="0bL7hVFPl1JoPxkN" class="nitrohosting-col">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="NzWcP9a0guzWhux5" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-memory" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">DDR4 ECC RAM</div>
													<div class="nitrohosting-service-text">
														<p>We use modern DDR4 ECC RAM preventing data corruption.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="tTibjd5YXpOVLft9" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-shield-alt" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Advanced DDoS Protection</div>
													<div class="nitrohosting-service-text">
														<p>Our DDoS mitigation technology scan and block malicious traffic.<br />
														</p></div>
												</div>
											</div>
										</div>
										<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="FBTQNbc5sq1zQGoF" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-user-friends" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Quality Support</div>
													<div class="nitrohosting-service-text">
														<p>Our staffs are well trained and knowledgeable, all ready for you.<br />
														</p></div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="X9CCcErTicVYI5jA" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="Z6stfRnGguX9o42w" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="jVOrZMwIVl3gl5YH" class="nitrohosting-counter nitrohosting-counter-start">
											<div class="nitrohosting-counter-holder">
												<div class="nitrohosting-counter-content">
													<span id="counter-uptime" class="nitrohosting-counter-display"></span>
													<span>%</span>
												</div>
												<span class="nitrohosting-counter-info">Average Uptime</span></div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="rC2rXp7tz2GVaFWj" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="jVOrZMwIVl3gl5YH" class="nitrohosting-counter">
											<div class="nitrohosting-counter-holder">
												<div class="nitrohosting-counter-content">
													<span id="counter-location" class="nitrohosting-counter-display"></span>
												</div>
												<span class="nitrohosting-counter-info">Server Locations</span></div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="cUeoa5MJvZTRtI8i" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div v-scroll-reveal="{ origin: 'right' }" nitrohosting-id="jVOrZMwIVl3gl5YH" class="nitrohosting-counter">
											<div class="nitrohosting-counter-holder">
												<div class="nitrohosting-counter-content">
													<span id="counter-client" class="nitrohosting-counter-display"></span>
												</div>
												<span class="nitrohosting-counter-info">Clients Served</span></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="product" nitrohosting-id="oPbG1iGr7rwj1dTM" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="PatkjiQDr5GofbTr" class="nitrohosting-col">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="nc796s2pF66f8wUs" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder">Products</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="e5t8bQsGkA8mgUvS" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal nitrohosting-id="kDjGzumlFvcuuzXx" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="M7v6ix4rumdhy2MQ" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="TmSms4JLYqrMtvtJ" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>Game Hosting</h2></div>
										</div>
										<div nitrohosting-id="WNQJEeMXEYH1fePw" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Need a game server to play with your friends? We host them for you!</div>
										</div>
										<div nitrohosting-id="bEJNyipsuE9iDwkN" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Game'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
								<div nitrohosting-id="SfLR70jZckIp8gdH" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="vKPdc3kcAg1ZRIYj" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>Web Hosting</h2></div>
										</div>
										<div nitrohosting-id="dHD7cWVObsPv3F5I" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Need a high-performance web server? We are here!</div>
										</div>
										<div nitrohosting-id="jjsFc64kevAFz7QW" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Web'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
								<div nitrohosting-id="1fSeRtgd8ATX2Brd" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="k9MFEHBdcgr5V4OJ" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>VPS Hosting</h2></div>
										</div>
										<div nitrohosting-id="CxBx6eKjBEdqXQ1y" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Powerful virtual server to fit your needs. Managed support included!</div>
										</div>
										<div nitrohosting-id="XiarGtudLQYbqDIk" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Vps'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
								<div nitrohosting-id="HF72Gv5RO3WJnYnC" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="l3K2NVq9qE8oeGwF" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder"><h2>Dedicated</h2></div>
										</div>
										<div nitrohosting-id="4YgpzsoBqfcE1wgs" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Ultimate managed dedicated server for your next level project!</div>
										</div>
										<div nitrohosting-id="c1Ro3B3jTpgjkXYe" class="nitrohosting-btn nitrohosting-animation-grow">
											<router-link class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right" :to="{name: 'Dedicated'}">
												<span class="nitrohosting-btn-text">Browse</span>
											</router-link>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div v-scroll-reveal nitrohosting-id="17jymzfgfTTP1q0t" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="gDLSpODnuvXohmQh" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder"></div>
								</div>
								<div nitrohosting-id="cn1mRAQVhiyTwUo2" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="3LVK6LsnYlIDb2El" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">VPN Service</div>
										</div>
										<div nitrohosting-id="IN2yazexLqAsyCMH" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Do not trust your internet? We offer fast and encrypted VPN.</div>
										</div>
										<div nitrohosting-id="xNoHqH61ntQVrviS" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right">
												<span class="nitrohosting-btn-text">Coming Soon</span> </a>
										</div>
									</div>
								</div>
								<div nitrohosting-id="GZZyrRqoZSuCjJd4" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="P8Cuh9cvxtk3Lt5B" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Web Reseller</div>
										</div>
										<div nitrohosting-id="NS7WX1W0nVkCx2W6" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Start your web hosting business with our reselling program.</div>
										</div>
										<div nitrohosting-id="LYaiTAwaRxOrV2uT" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right">
												<span class="nitrohosting-btn-text">Coming Soon</span> </a>
										</div>
									</div>
								</div>
								<div nitrohosting-id="az3JLAu3ZPYyo8Xm" class="nitrohosting-col nitrohosting-col-3">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="4Vuic1OhDHJtiCi5" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">VPS Reseller</div>
										</div>
										<div nitrohosting-id="06EG4PYXnbUHMvLf" class="nitrohosting-heading" style="text-align: center;">
											<div class="nitrohosting-heading-holder">Want to start selling VPS? We are here to back you up!</div>
										</div>
										<div nitrohosting-id="EAyGk6bANGonHpe4" class="nitrohosting-btn nitrohosting-animation-grow">
											<a class="nitrohosting-btn-holder nitrohosting-ele-link nitrohosting-btn-custom nitrohosting-btn-mini nitrohosting-btn-icon-right">
												<span class="nitrohosting-btn-text">Coming Soon</span> </a>
										</div>
									</div>
								</div>
								<div nitrohosting-id="uMrFUzCaWnshdMak" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="testimonial" nitrohosting-id="FWIZzzMzZjvGCUs2" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="vfZRoYZ3lRpuC6tA" class="nitrohosting-col">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'bottom' }" nitrohosting-id="bkvhnEII3CSlhnHQ" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Testimonials</div>
						</div>
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="pE9V4SmfGtqwnWLa" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div nitrohosting-id="xIBpqKaAoTvda9L0" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="6cgcxiEAqedTGewj" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="5aGecykzr2xoI7IJ" class="nitrohosting-quote">
											<div class="nitrohosting-quote-holder nitrohosting-quote-quotation">
												<i class="fa fa-quote-left nitrohosting-quotation-overlay"></i>
												<div class="nitrohosting-quote-content"> NitroHosting handled most troubleshooting for me. You know they are best when you're not once ever alone trying to figure things out!</div>
												<div class="nitrohosting-quote-cite">
													<span class="nitrohosting-cite-holder">- Goot, Hyvoid LLC</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="B8zHa7iGk7TMe5va" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="rRrETkxEvyY7f59v" class="nitrohosting-quote">
											<div class="nitrohosting-quote-holder nitrohosting-quote-quotation">
												<i class="fa fa-quote-left nitrohosting-quotation-overlay"></i>
												<div class="nitrohosting-quote-content"> I have been a client of NitroHosting for a long time. Servers have been running fine, I am glad that I chose NitroHosting over the others.</div>
												<div class="nitrohosting-quote-cite">
													<span class="nitrohosting-cite-holder">- Felipe, Client</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="VaGrCcztWTN9I5ju" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="GfsfG7Ib79TVmHV7" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="vp4WusjURH3KZyyJ" class="nitrohosting-quote">
											<div class="nitrohosting-quote-holder nitrohosting-quote-quotation">
												<i class="fa fa-quote-left nitrohosting-quotation-overlay"></i>
												<div class="nitrohosting-quote-content"> NitroHosting is good and Ivan is amazing with support. Plus Jacob is super active and responds to everything so proper to him!</div>
												<div class="nitrohosting-quote-cite">
													<span class="nitrohosting-cite-holder">- ICourt, Client</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="zZdJddOva78WOBgi" class="nitrohosting-col nitrohosting-col-6">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="WN127wyn64R5JnJ6" class="nitrohosting-quote">
											<div class="nitrohosting-quote-holder nitrohosting-quote-quotation">
												<i class="fa fa-quote-left nitrohosting-quotation-overlay"></i>
												<div class="nitrohosting-quote-content"> NitroHosting was really supportive and helpful to any requests we had. Server is fast and has never crashed unlike other hosts.</div>
												<div class="nitrohosting-quote-cite">
													<span class="nitrohosting-cite-holder">- PuppyFaceTreat, NCR Games</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div nitrohosting-id="sFARASR5Wf2KofEn" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div nitrohosting-id="ZD6Y3gy0jEfY3N2p" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder"></div>
								</div>
								<div nitrohosting-id="lL4NP60VnVgICUv7" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="y9ZiiwEKp8xKrPhP" class="nitrohosting-quote">
											<div class="nitrohosting-quote-holder nitrohosting-quote-quotation">
												<i class="fa fa-quote-left nitrohosting-quotation-overlay"></i>
												<div class="nitrohosting-quote-content"> They have not messed up once! I enjoy their on point support and the wide range of different services for a cheap price!</div>
												<div class="nitrohosting-quote-cite">
													<span class="nitrohosting-cite-holder">- Exehad, Client</span>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div nitrohosting-id="D6QeHr46kwTdFKd4" class="nitrohosting-col nitrohosting-col-1">
									<div class="nitrohosting-col-holder"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="contact" nitrohosting-id="9DnXq6khbLl0Vgxa" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<div nitrohosting-id="zOzbgoPOeXd4rFJi" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div v-scroll-reveal="{ origin: 'left' }" nitrohosting-id="m163u0W6wCT9Ebag" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> Contact Us</div>
						</div>
						<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="lFdJLQniwJAur1Lq" class="nitrohosting-divider">
							<div class="nitrohosting-divider-holder">
								<span class="nitrohosting-divider-separator"></span></div>
						</div>
						<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="e26EaNnSqlPLbTXX" class="nitrohosting-heading" style="text-align: center;">
							<div class="nitrohosting-heading-holder"> We would gladly hear from you! If you have any question, feel free to contact us below! Also follow us on social media for latest news!</div>
						</div>
						<div nitrohosting-id="6lcKJ1xLur6aJbSn" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-full">
								<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="ycFh2DbGU8jkxeZV" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="cEB2Svmmm5u3rtC5" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fas fa-envelope-open" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Email Address</div>
													<div class="nitrohosting-service-text"></div>
												</div>
											</div>
										</div>
										<div nitrohosting-id="XRS6yydxfUeSuXAC" class="nitrohosting-heading" style="text-align: center;">
											<a href="mailto:support@nitrohosting.pro">
												<div class="nitrohosting-heading-holder"> support@nitrohosting.pro</div>
											</a></div>
									</div>
								</div>
								<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="cIT4SQ6J1uX0BytR" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="5zs74Ext34yUK8Gt" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fab fa-whmcs" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Support Ticket</div>
													<div class="nitrohosting-service-text"></div>
												</div>
											</div>
										</div>
										<div nitrohosting-id="TfhQNbtIpqovoFC5" class="nitrohosting-heading" style="text-align: center;">
											<a href="https://billing.nitrohosting.pro/submitticket.php" target="_blank">
												<div class="nitrohosting-heading-holder"> Click Here To Go</div>
											</a></div>
									</div>
								</div>
								<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="Myb4yWZ523YZkczA" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="SUWWZvlunKrmb7pa" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fab fa-discord" aria-hidden="true"></i></div>
												<div class="nitrohosting-service-details">
													<div class="nitrohosting-service-heading">Discord Server</div>
													<div class="nitrohosting-service-text"></div>
												</div>
											</div>
										</div>
										<div nitrohosting-id="TrK7xKOL4GTRTfD6" class="nitrohosting-heading" style="text-align: center;">
											<a href="https://discord.nitrohosting.pro/" target="_blank">
												<div class="nitrohosting-heading-holder"> Join Now</div>
											</a></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div nitrohosting-id="WOMxTpXYNYTkzy21" class="nitrohosting-col nitrohosting-col-6">
					<div class="nitrohosting-col-holder">
						<div nitrohosting-id="rrG7v72ptRSq7sNm" class="nitrohosting-inner_row nitrohosting-row-stretch-auto nitrohosting-height-default">
							<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
								<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="7e8alTpjnMizjb69" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="gE4OtvrDJDYb77eb" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fab fa-twitter-square" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<a href="https://twitter.com/NitroHostingPro/" class="nitrohosting-ele-link">
														<div class="nitrohosting-service-heading"> @NitroHostingPro</div>
													</a>
													<div class="nitrohosting-service-text"></div>
												</div>
												<a href="https://twitter.com/NitroHostingPro/" class="nitrohosting-ele-link nitrohosting-box-link"> </a>
											</div>
										</div>
									</div>
								</div>
								<div v-scroll-reveal="{ origin: 'top' }" nitrohosting-id="Dj9tHjBSzlzDgWCy" class="nitrohosting-col nitrohosting-col-4">
									<div class="nitrohosting-col-holder">
										<div nitrohosting-id="ApROuB0ftp6TFyBG" class="nitrohosting-iconbox">
											<div class="nitrohosting-service-container nitrohosting-service-align-top">
												<div class="nitrohosting-service-icon nitrohosting-service-default">
													<i class="fab fa-facebook-square" aria-hidden="true"></i>
												</div>
												<div class="nitrohosting-service-details">
													<a href="https://www.facebook.com/nitrohosting.pro/" class="nitrohosting-ele-link">
														<div class="nitrohosting-service-heading"> @NitroHosting</div>
													</a>
													<div class="nitrohosting-service-text"></div>
												</div>
												<a href="https://www.facebook.com/nitrohosting.pro/" class="nitrohosting-ele-link nitrohosting-box-link"> </a>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div id="banner" nitrohosting-id="akVZExC7eO8dFg1E" class="nitrohosting-row nitrohosting-row-stretch-auto nitrohosting-height-default">
			<div class="nitrohosting-row-holder nitrohosting-row nitrohosting-auto nitrohosting-width-auto">
				<carousel :per-page="3" :autoplay="true" :autoplayHoverPause="true" :pagination-enabled="false" class="nitrohosting-carousel nitrohosting-carousel-holder">
					<slide class="nitrohosting-carousel-slide">
						<img src="https://img.nitrohosting.pro/tebex.svg" alt="tebex">
					</slide>
					<slide class="nitrohosting-carousel-slide">
						<img src="https://img.nitrohosting.pro/colocrossing.png" alt="colocrossing">
					</slide>
					<slide class="nitrohosting-carousel-slide">
						<img src="https://img.nitrohosting.pro/reliablesite.svg" alt="reliablesite">
					</slide>
					<slide class="nitrohosting-carousel-slide">
						<img src="https://img.nitrohosting.pro/hetzner.png" alt="hetzner">
					</slide>
				</carousel>
			</div>
		</div>
	</main>
</template>
<script>
	export default {
		name: "Home",
		metaInfo: {
			title: "NitroHosting - Your Perfect Hosting Solution",
			titleTemplate: null
		}
	};

	let header = document.querySelector(".nitrohosting-header > .nitrohosting-row");
	header.classList.remove("is-fixed");

	document.addEventListener("CounterStart", function() {
		counter("counter-uptime", 0, 99, 2500);
		counter("counter-location", 0, 4, 2500);
		counter("counter-client", 0, 150, 2500);
	});

	function counter(id, start, end, duration) {
		let obj = document.getElementById(id),
			current = start,
			range = end - start,
			increment = end > start ? 1 : -1,
			step = Math.abs(Math.floor(duration / range)),
			timer = setInterval(() => {
				current += increment;
				obj.textContent = current;
				if (current === end) {
					clearInterval(timer);
				}
			}, step);
	}
</script>
<style scoped>
	@import "../assets/css/pages/Home.css";
</style>